import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { initI18nLean } from '@wix/communities-blog-client-common';
import '../../../rich-content/components/rich-content-editor/rich-content-pg.global.scss';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import PermissionsProvider from '../../../common/components/permissions-provider';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import { getLanguage, isEditor, getIsICUEnabled } from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import AppLoaded from '../../../common/components/app-loaded';
import { isInWix } from '../../../common/services/is-in-wix';
import { getIsCreatedWithResponsiveEditor } from '../../../common/selectors/app-settings-selectors';
import MemberPosts from '../member-posts';
import PageHeader from '../page-header';
import styles from './app-root.scss';

class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (this.props.isEditor && this.props.createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }

  getI18nConfig = () => {
    const { language, translations, isICUEnabled } = this.props;

    if (!this.i18nConfig) {
      this.i18nConfig = initI18nLean(language, translations, isICUEnabled);
    }

    return this.i18nConfig;
  };

  render() {
    const i18n = this.getI18nConfig();

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <I18nextProvider i18n={i18n}>
            <div className={styles.baseStyles}>
              <PageHeader />
              <MemberPosts />
              <PopoverRoot />
              <AppLoaded />
            </div>
          </I18nextProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  isICUEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isEditor: isEditor(state),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isICUEnabled: getIsICUEnabled(state),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
