import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, ALIGNMENT } from 'wix-ui-tpa/Tabs';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TextButton, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import withTranslate from '../../../common/hoc/with-translate';
import { connect } from '../../../common/components/runtime-context';
import { getTopology } from '../../../common/store/topology/topology-selectors';
import { getCreatePostUrl } from '../../../common/services/get-post-url';
import { getActiveTab } from '../../store/active-tab/active-tab-selectors';
import {
  MEMBER_POSTS_PAGE_TAB_DRAFTS,
  MEMBER_POSTS_PAGE_TAB_PUBLICATIONS,
  MEMBER_POSTS_PAGE_TAB_SUBMISSIONS,
} from '../../constants/member-posts-page';
import styles from './page-header.scss';

const TAB_BY_TAB_INDEX = [
  MEMBER_POSTS_PAGE_TAB_PUBLICATIONS,
  MEMBER_POSTS_PAGE_TAB_SUBMISSIONS,
  MEMBER_POSTS_PAGE_TAB_DRAFTS,
];

class PageHeader extends React.Component {
  handleTabClick = async (tabIndex) => {
    const tab = TAB_BY_TAB_INDEX[tabIndex];
    if (tab) {
      await this.props.setActiveTab(tab);
      await this.props.fetchPageData();
    }
  };

  render() {
    const { t, activeTab, createPostUrl } = this.props;

    return (
      <>
        <div className={styles.header}>
          <Text tagName="h3" typography={TYPOGRAPHY.largeTitle}>
            {t('page-title')}
          </Text>
          <TextButton
            as="a"
            href={createPostUrl}
            className={styles.createPostButton}
            priority={TEXT_BUTTON_PRIORITY.primary}
          >
            {t('create-post-button.label')}
          </TextButton>
        </div>

        <div className={styles.tabs}>
          <Tabs
            items={[{ title: t('tabs.publications') }, { title: t('tabs.submissions') }, { title: t('tabs.drafts') }]}
            activeTabIndex={TAB_BY_TAB_INDEX.indexOf(activeTab)}
            onTabClick={this.handleTabClick}
            alignment={ALIGNMENT.left}
          />
        </div>
      </>
    );
  }
}

PageHeader.propTypes = {
  activeTab: PropTypes.string.isRequired,
  postPageSectionUrl: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  fetchPageData: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  activeTab: getActiveTab(state),
  createPostUrl: getCreatePostUrl(getTopology(state)),
  setActiveTab: actions.setActiveTabPromisified,
  fetchPageData: actions.fetchMemberPostsPageDataPromisified,
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(PageHeader);
